
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { PageComponent } from '@/store/page';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import AppTab from '@/components/blocks/base/AppTab.vue';
    export interface Tabs {
        title: string;
        text: string;
        link: string;
        img: Object;
    }

    export const meta: ComponentMeta = {
        title: 'Вкладки с одним изображением и текстом',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок'
            },
            tabs: {
                title: 'Вкладки',
                type: AvailableTypes.array,
                item: {
                    img: {
                        type: AvailableTypes.image,
                        title: 'Картинка'
                    },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    },
                    link: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    },
                    targetBlank: {
                        type: AvailableTypes.boolean,
                        title: 'Открыть ссылку в новом окне'
                    }
                }
            }
        },
        group: AvailableGroups.Вкладки
    };

    @Component({
        components: { ImageComponent, BaseTabs, AppTab }
    })
    export default class TabsWithOneImageAndText extends mixins(PageBlockMixin) {
        @Prop(String) title;
        @Prop(Array) tabs: Tabs[];
        @Prop() component: PageComponent;
    }
