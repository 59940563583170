
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import QuestionCollapse from '@/components/question/QuestionCollapse.vue';
    import Button from '@/components/blocks/Button.vue';
    import { PageComponent } from '@/store/page';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import AppTab from '@/components/blocks/base/AppTab.vue';

    export const meta = {
        title: 'Вопрос-ответ',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Вопрос ответ'
            },
            tabs: {
                title: 'Вкладки',
                type: AvailableTypes.array,
                item: {
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    content: {
                        type: AvailableTypes.object,
                        item: {
                            items: {
                                title: 'Элементы',
                                type: AvailableTypes.array,
                                item: {
                                    question: {
                                        type: AvailableTypes.string,
                                        title: 'Вопрос'
                                    },
                                    answer: {
                                        type: AvailableTypes.editor,
                                        title: 'Ответ'
                                    }
                                }
                            },
                            text: {
                                type: AvailableTypes.string,
                                title: 'Текст'
                            }
                        }
                    }
                },
                default: optionsDefault.QuestionAnswer
            },
            textButton: {
                type: AvailableTypes.string,
                title: 'Текст кнопки',
                default: 'Больше вопросов'
            }
        },
        group: AvailableGroups.Список
    };

    @Component({
        components: { BaseTabs, QuestionCollapse, Button, AppTab }
    })
    export default class QuestionAnswer extends PageBlockMixin {
        @Prop(String) title;
        @Prop(Array) tabs;
        @Prop() component: PageComponent;
        @Prop() index;
        @Prop() textButton;

        activeTab = 0;
        countQuestions = 6;
        displayedItemsCount = 6;

        changeTab(tabindex) {
            if (this.activeTab !== tabindex) {
                this.displayedItemsCount = 6;
            }

            this.activeTab = tabindex;
        }

        get hasMore(): boolean {
            if (this.tabs[this.activeTab] !== undefined) {
                return (
                    this.displayedItemsCount <
                    this.tabs[this.activeTab].content.items.length
                );
            } else return false;
        }

        get displayedItems() {
            if (
                this.tabs[this.activeTab] !== undefined &&
                this.tabs[this.activeTab].content
            ) {
                return this.tabs[this.activeTab].content.items.slice(
                    0,
                    this.displayedItemsCount
                );
            }
            return [];
        }

        loadMore() {
            this.displayedItemsCount += 6;
        }
    }
